import React, { Fragment } from "react";
import "../../styles/footer.scss";
import { logo } from "../../assests/imageModule/image";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
const Footer = () => {
  return (
    <Fragment>
      <section className="footer_wrapper">
        <div className="container">
          <div className="footer_content">
            <div className="logo">
              <img src={logo} alt="footer_logo" />
              <Link to="/">
                <span>
                    Email :
                </span>
                <span>
                    help@saajfarms.com
                </span>
              </Link>
            </div>
            <div className="useful_links">
              <ul>
                <h2>Policies</h2>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/shipping-policy">Shipping Policy</Link>
                </li>
                <li>
                  <Link to="/refund-policy">Refund Policy</Link>
                </li>
                <li>
                  <Link to="/">Terms & Conditions</Link>
                </li>
              </ul>
            </div>
            <div className="useful_links">
              <ul>
                <h2>Useful Links</h2>
                <li>
                  <Link to="/">Help & Support</Link>
                </li>
                <li>
                  <Link to="/">About Us</Link>
                </li>
                {/* <li>
                  <Link to="/">Refund Policy</Link>
                </li> */}
              </ul>
            </div>
            <div className="social_media">
              <h2>Connect with us :</h2>
              <ul>
                <li>
                  <Link to="/">
                    <FaInstagram />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <FaTwitter />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <FaFacebook />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <FaLinkedin />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <p>Copyright © SAAJ FARMS. All rights reserved</p>
        </div>
      </section>
    </Fragment>
  );
};

export default Footer;
