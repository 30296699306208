import { CiDeliveryTruck } from "react-icons/ci";
import { FaRegClock, FaRegThumbsUp } from "react-icons/fa";
import { RiSecurePaymentLine } from "react-icons/ri";
export const garrantyArray = [
  {
    id: 1,
    icon: <CiDeliveryTruck />,
    title: "Purity Guarantee",
    label: "100% purity",
  },
  {
    id: 2,
    icon: <FaRegThumbsUp />,
    title: "Money Guarantee",
    label: "7 days return",
  },
  {
    id: 3,
    icon: <FaRegClock />,
    title: "7 Days",
    label: "For free return",
  },
  {
    id: 4,
    icon: <RiSecurePaymentLine />,
    title: "Payment",
    label: "Secure system",
  },
];
