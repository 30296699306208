import React, { Fragment } from "react";
import "../../styles/WebLayout.scss";
// import { logo } from "../../assests/imageModule/image";
import { BsBag } from "react-icons/bs";
import { CiUser } from "react-icons/ci";
import { Link } from "react-router-dom";
import SearchBox from "../reusable/SearchBox";
import { logo } from "../../assests/imageModule/image";
const WebLayout = ({ headerLinks }) => {
  return (
    <Fragment>
      <section className="header_wrapper">
        <div className="container">
          <div className="header">
            <div className="logo">
              <img src={logo} alt="logo" />
            </div>
            <div className="search_box">
              <SearchBox type="search" />
            </div>
            <div className="login_links">
              <ul>
                <li>
                  <Link to="/">
                    <BsBag />
                  </Link>
                </li>
                <li>
                  <Link to="/login">
                    <CiUser />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="sidebar_link">
        <div className="container">
          <div className="header_links">
            <ul>
              {headerLinks?.map((itm) => (
                <li key={itm?.id}>
                  <Link className="span" to={`${itm?.link}`}>
                    {itm?.icon}
                  </Link>
                  <Link to={`${itm?.link}`} className="txt_link">
                    {itm?.label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default WebLayout;
