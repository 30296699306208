import React, { Fragment } from "react";
import WebLayout from "../../components/Layout/WebLayout";
import { webLinks } from "../../components/Layout/WebsiteLinks";
import Footer from "../../components/Layout/Footer";
import "../../styles/policy.scss";
const ShippingPolicy = () => {
  return (
    <Fragment>
      <WebLayout headerLinks={webLinks} />
      <div className="container">
        <section className="policy_wrapper">
          <div className="title">
            <h4>Shipping Policy</h4>
            <p>(Last Updated: 03-March-2024)</p>
          </div>
          <div className="shipping_txt">
            <p>
              We usually ship orders within 2 to 3 working days (except Sundays
              & National Holidays) of receiving your order.
            </p>
            <p>
              We have partnered with multiple Courier service providers for
              shipping across India. Depending on the destination location, it
              takes between 2 to 9 days for an order to be delivered.
            </p>
            <p>Currently, we accept orders only from India.</p>
            <p>
              We reserve the right to cancel an order, within 48 hours of
              receiving the order.
            </p>
            <p>
              Expected Delivery times may be impacted due to operational
              challenges beyond our control.
            </p>
            <p>
              For any feedback or concerns on shipping or delivery, please reach
              out to us at care@saajfarms.in
            </p>
          </div>
        </section>
      </div>

      <Footer />
    </Fragment>
  );
};

export default ShippingPolicy;
