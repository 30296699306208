import React, { Fragment } from "react";
import WebLayout from "../../components/Layout/WebLayout";
import { webLinks } from "../../components/Layout/WebsiteLinks";
import Footer from "../../components/Layout/Footer";
import "../../styles/policy.scss";
const RefundPolicy = () => {
  return (
    <Fragment>
      <WebLayout headerLinks={webLinks} />

      <div className="container">
        <section className="policy_wrapper">
          <div className="title">
            <h4>Refund Policy</h4>
            <p>(Last Updated: 03-March-2024)</p>
          </div>
          <p>
            We strive to offer our customers quality products, in sustainable
            packaging to minimise the impact on the environment.
          </p>
          <p>
            Owing to sustainable Glass packaging and the perishable & consumable
            nature of our products, we do not accept Returns of products.
          </p>
          <p>
            We also do not offer Refunds for our products in case of Returns.
          </p>
          <p>
            Cancellation of your order is not possible once the order has been
            confirmed or shipped.
          </p>
          <div className="body_txt">
            <pre>Transit Damges:</pre>
            <ul>
              <li>
                We advise you to check the package condition on receiving the
                product.
              </li>
              <li>
                In case you receive a product in a damaged condition from our
                Courier Partner, please reach out to us with photographs of the
                damaged product and your invoice within 24 hours of receipt of
                order, at care@saajfarms.in or WhatsApp us on +91 98990 99172.{" "}
              </li>
              <li>
                Our Customer Care Team will review your request and revert with
                an appropriate solution to your concern, within 3-4 working days
                (except Sundays & National Holidays). We may replace the damaged
                product, or refund your payment towards the damaged product, on
                a case-to-case basis, at our discretion.
              </li>
            </ul>
          </div>
        </section>
      </div>

      <Footer />
    </Fragment>
  );
};

export default RefundPolicy;
