import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/policy/PrivacyPolicy";
import ShippingPolicy from "./pages/policy/ShippingPolicy";
import RefundPolicy from "./pages/policy/RefundPolicy";

const App = () => {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/shipping-policy" element={<ShippingPolicy />} />
        <Route exact path="/refund-policy" element={<RefundPolicy />} />
      </Routes>
    </>
  );
};

export default App;
