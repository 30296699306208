import React, { Fragment } from "react";
import WebLayout from "../components/Layout/WebLayout";
import { webLinks } from "../components/Layout/WebsiteLinks";
import "../styles/home.scss";
import { garrantyArray } from "../components/array/garrantyArray";
import ProductCard from "./product/ProductCard";
import { Link } from "react-router-dom";
import { IoMdArrowDropright } from "react-icons/io";
// import { FaCow } from "react-icons/fa6";
// import { LuMilk } from "react-icons/lu";
// import { GiHoneyJar } from "react-icons/gi";
import Footer from "../components/Layout/Footer";
const Home = () => {
  return (
    <Fragment>
      <WebLayout headerLinks={webLinks} />
      <section className="banner_wrapper">
        <h2>Swaad se ,Swastha tak</h2>
      </section>
      <div className="container">
        <div className="garrantee">
          {garrantyArray?.map((ele) => (
            <div className="garanty_card" ele={ele?.id}>
              <div className="icon">{ele?.icon}</div>
              <div className="desc">
                <p>{ele?.title}</p>
                <span>{ele?.label}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="container">
        <section className="products">
          {/* <div className="categories">
            <div className="category_card">
              <p className="title">Filter By :-</p>
              <div className="filter">
                <button>
                  <span>
                    <FaCow />
                    <p>A2 Cow Ghee</p>
                  </span>
                  <span>
                    <IoMdArrowDropright />
                  </span>
                </button>
                <button>
                  <span>
                    <LuMilk /> <p>Buffalo Ghee</p>
                  </span>
                  <span>
                    <IoMdArrowDropright />
                  </span>
                </button>
                <button>
                  <span>
                    <GiHoneyJar /> <p>Honey</p>
                  </span>
                  <span>
                    <IoMdArrowDropright />
                  </span>
                </button>
              </div>
              <p className="title">Sort By :-</p>
              <div className="filter">
                <button>Top Rated</button>
                <button>Best Seller</button>
                <button>Most Reviews</button>
              </div>
            </div>
          </div> */}
          <div className="products_list">
            <div className="title">
              <h2>
                <p>Top Products</p>
                <span>Best Collection For You</span>
              </h2>
              <Link to="/">
                View All <IoMdArrowDropright />
              </Link>
            </div>
            <div className="product_listing">
              <ProductCard />
              {/* <ProductCard />
              <ProductCard />
              <ProductCard />
              <ProductCard />
              <ProductCard /> */}
            </div>
            {/* <div className="title">
              <h2>
                <p>Best Seller</p>
                <span>Best Selling Products For You</span>
              </h2>
              <Link to="/">
                View All <IoMdArrowDropright />
              </Link>
            </div>
            <div className="product_listing">
              <ProductCard />
              <ProductCard />
              <ProductCard />
              <ProductCard />
              <ProductCard />
              <ProductCard />
            </div> */}
          </div>
        </section>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Home;
