import { BiSolidOffer } from "react-icons/bi";
import { FaHome, FaShoppingBasket, FaStore } from "react-icons/fa";

export const webLinks = [
  {
    id: 1,
    label: "Home",
    link: "/",
    icon: <FaHome />,
  },
  {
    id: 2,
    label: "Best Deals",
    link: "/",
    icon: <BiSolidOffer />,
  },
  {
    id: 3,
    label: "Shop",
    link: "/",
    icon: <FaStore />,
    data: [
      {
        id: 1,
        label: "Ghee",
        category: "Ghee",
      },
      {
        id: 2,
        label: "Honey",
        category: "Honey",
      },
    ],
  },
  {
    id: 4,
    label: "All Products",
    link: "/",
    icon: <FaShoppingBasket />,
  },
  // {
  //   id: 5,
  //   label: "About Us",
  //   link: "/about-us",
  // },
];
