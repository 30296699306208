import React, { Fragment } from "react";
import { CiSearch } from "react-icons/ci";
import styled from "styled-components";
const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 200px;
  border: 1px solid rgb(243, 245, 249);
  border-radius: 20px;
  overflow: hidden;
  > input {
    border-radius: 0px 20px 20px 0px;
    outline: none;
    border: 1px solid rgb(251 121 116);
    box-shadow: none;
    font-size: 16px;
    padding: 6px 20px;
    border-radius: 20px 0px 0px 20px;
    text-transform: capitalize;
  }
  > button {
    background-color: rgb(251 121 116);
    border-radius: 0px 20px 20px 0px;
    outline: none;
    border: 1px solid rgb(251 121 116);
    color: #fff;
    border-left:none;
    padding: 6px 20px;
    font-size: 16px;
    &:hover {
      color: rgb(251 121 116);
      background-color: #fff;
    }
  }
`;
const SearchBox = ({ type, value, onChange, handleSearch }) => {
  return (
    <Fragment>
      <SearchWrapper>
        <input
        placeholder="Search any product"
         type={type}
          value={value}
           onChange={onChange} />
        <button title="Search" onClick={handleSearch}>
          <CiSearch />
        </button>
      </SearchWrapper>
    </Fragment>
  );
};

export default SearchBox;
