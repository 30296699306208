import React, { Fragment } from "react";
import WebLayout from "../../components/Layout/WebLayout";
import { webLinks } from "../../components/Layout/WebsiteLinks";
import Footer from "../../components/Layout/Footer";
import "../../styles/policy.scss";
const PrivacyPolicy = () => {
  return (
    <Fragment>
      <WebLayout headerLinks={webLinks} />

      <div className="container">
        <section className="policy_wrapper">
          <div className="title">
            <h4>Privacy Policy</h4>
            <p>(Last Updated: 03-March-2024)</p>
          </div>
          <p>
            This Privacy Policy describes how www.saajfarms.in (the “Site” or
            “we”) collects, uses, and discloses your Personal Information when
            you visit or make a purchase from the Site www.saajfarms.in
          </p>
          <div className="body_txt">
            <h6>Collecting Personal Information</h6>
            <p>
              When you visit the Site, we collect certain information about your
              device, your interaction with the Site, and information necessary
              to process your purchases. We may also collect additional
              information if you contact us for customer support. In this
              Privacy Policy, we refer to any information that can uniquely
              identify an individual (including the information below) as
              “Personal Information”. See the list below for more information
              about what Personal Information we collect and why.
            </p>
            <pre>Device information</pre>
            <ul>
              <li>
                <strong>Examples of Personal Information collected:</strong>{" "}
                version of web browser, IP address, time zone, cookie
                information, what sites or products you view, search terms, and
                how you interact with the Site.
              </li>
              <li>
                <strong>Source of collection:</strong> Collected automatically
                when you access our Site using cookies, log files, web beacons,
                tags, or pixels and other miscellaneous technologies
              </li>
            </ul>
            <pre>Order information</pre>
            <ul>
              <li>
                <strong>Source of collection:</strong> to provide products or
                services to you to fulfill our contract, to process your payment
                information, arrange for shipping, and provide you with invoices
                and/or order confirmations, communicate with you, screen our
                orders for potential risk or fraud, and when in line with the
                preferences you have shared with us, provide you with
                information or advertising relating to our products or services.
              </li>
              <li>
                <strong>Source of collection:</strong> collected from you.
              </li>
              <li>
                <strong>Disclosure for a business purpose:</strong> shared with
                our processor Shopify, WhatsApp and other Service Providers
              </li>
            </ul>
          </div>
          <div className="body_txt">
            <h6>Sharing Personal Information</h6>
            <p>
              We share your Personal Information with service providers to help
              us provide our services and fulfill our contracts with you, as
              described above. For example:
            </p>
            <ul>
              <li>
                We may share your Personal Information to comply with applicable
                laws and regulations, to respond to a subpoena, search warrant
                or other lawful request for information we receive, or to
                otherwise protect our rights.
              </li>
              <li>Other Service Providers</li>
            </ul>
          </div>
          <div className="body_txt">
            <h6>Behavioural Advertising</h6>
            <p>
              As described above, we use your Personal Information to provide
              you with targeted advertisements or marketing communications we
              believe may be of interest to you. For example:
            </p>
            <ul>
              <li>
                We share information about your use of the Site, your purchases,
                and your interaction with our ads on other websites with our
                advertising partners. We collect and share some of this
                information directly with our advertising partners, and in some
                cases through the use of cookies or other similar technologies
                (which you may consent to, depending on your location).
              </li>
            </ul>
          </div>
        </section>
      </div>

      <Footer />
    </Fragment>
  );
};

export default PrivacyPolicy;
