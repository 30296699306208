import React from "react";
import { MdAdd } from "react-icons/md";
import styled from "styled-components";
import { product } from "../../assests/imageModule/image";
const ProductWrapper = styled.div`
  padding: 12px;
  box-sizing: border-box;
  border-radius: 6px;
  width: 100%;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.043) 0px 2px 2px 0px, rgba(0, 0, 0, 0.035) 0px 1px 5px 0px;
  > .product_img {
    display: flex;
    justify-content: center;
    > img {
      width: 100%;
      aspect-ratio: 3/2;
      object-fit: contain;
    }
  }
  > .desc {
    margin: 12px 0px;
    > strong {
      font-size: 14px;
      font-family: Regular;
      font-weight: 600;
      margin-bottom: 12px;
      text-transform: capitalize;
    }
    > p {
      font-size: 12px;
      font-family: Regular;
      font-weight: 400;
      color: #555;
      margin-bottom: 0px;
      line-height: 1.4;
      text-transform: capitalize;
    }
  }
  > .cart {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 767px) {
      flex-direction: column;
      row-gap: 20px;
    }
    > .price {
      display: flex;
      column-gap: 15px;
      font-size: 14px;
      font-family: "Heading";
      > strong {
        color: #333;
      }
      > strike {
        color: #555;
        font-weight: 300;
        font-size: 12px;
      }
    }
    > .btn {
      > button {
        width: 30px;
        height: 30px;
        box-shadow: 0px 0px 12px 0px #e5e5e5e;
        border-radius: 5px;
        border: none;
        background-color: #fff;
        outline: none;
        color: rgb(251 121 116);
        border: 1px solid rgb(251 121 116);
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          background-color: rgb(251 121 116);
          color: #fff;
        }
        @media (max-width: 767px) {
          width: 100%;
        }
      }
        @media (max-width: 767px) {
          width: 100%;
        }
    }
  }
`;
const ProductCard = () => {
  return (
    <ProductWrapper>
      <div className="product_img">
        <img src={product} alt={product} />
      </div>
      <div className="desc">
        <strong>product title</strong>
        <p>quantity</p>
      </div>
      <div className="cart">
        <div className="price">
          <strong>INR Rs.100 /-</strong>
          <strike>Rs.120 /-</strike>
        </div>
        <div className="btn">
          <button title="Add to cart">
            <MdAdd />
          </button>
        </div>
      </div>
    </ProductWrapper>
  );
};

export default ProductCard;
